//Styles
import '../sass/index.scss';

import Swiper, {
  Grid,
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
} from 'swiper';

const menuBtn = document.querySelector('.menu-btn');
const hamburger = document.querySelector('.menu-btn__burger');
const nav = document.querySelector('.navbar-primary');
const menuNav = document.querySelector('.navbar-item');
const navItems = document.querySelectorAll('.navbar-item__item');

let showMenu = false;

menuBtn.addEventListener('click', toggleMenu);

function toggleMenu() {
  if (!showMenu) {
    hamburger.classList.add('open');
    nav.classList.add('open');
    menuNav.classList.add('open');
    navItems.forEach((item) => item.classList.add('open'));

    showMenu = true;
  } else {
    hamburger.classList.remove('open');
    nav.classList.remove('open');
    menuNav.classList.remove('open');
    navItems.forEach((item) => item.classList.remove('open'));

    showMenu = false;
  }
}

// init Swiper:
const swiper1 = new Swiper('.swiper1', {
  // configure Swiper to use modules
  modules: [Navigation, Pagination],
  touch: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 10,
  grabCursor: true,
  
  breakpoints: {
    // when window width is >= 480px
    600: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 10,
    },
    960: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 10,
    },
    1470: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 10,
    }
  },
  
  loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
const swiper2 = new Swiper('.swiper2', {
  modules: [Autoplay, EffectFade],
  touch: true,
  autoplay: {
    delay: 5000,
  },

  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
});
const swiper3 = new Swiper('.swiper3', {
  // configure Swiper to use modules
  modules: [Grid, Navigation, Pagination],
  touch: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  grid: {
    rows: 1,
  },
  spaceBetween: 10,
  loop: false,
  breakpoints: {
    480: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      grid: {
       rows: 3,
      },
      spaceBetween: 10,
     },
     720: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      grid: {
       rows: 3,
      },
      spaceBetween: 10,
     },
    1200: {
      slidesPerView: 6,
      slidesPerColumn: 5,
      grid: {
        rows: 5,
        spaceBetween: 10,
      },
      
    },
  },
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

const swiper4 = new Swiper('.swiper4', {
  modules: [Grid, Navigation, Pagination],
  touch: true,
  slidesPerView: 3,
  grid: {
    rows: 2,
  },
  spaceBetween: 30,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});
const swiper5 = new Swiper('.swiper5', {
  touch: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 10,

  breakpoints: {
    // when window width is >= 480px
    600: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 20,
      navigation: false,
    },
  },

  // configure Swiper to use modules
  modules: [Navigation, Pagination],
  loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

let swiper6 = new Swiper('.swiper6', {
  // configure Swiper to use modules
  modules: [Grid, Navigation, Pagination],
  touch: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  grid: {
    rows: 6,
  },
  spaceBetween: 10,

  loop: false,
  breakpoints: {
    480: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      grid: {
        rows: 3,
      },
    },
    720: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      grid: {
        rows: 3,
      },
    },
    960: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      grid: {
        rows: 3,
      },
      spaceBetween: 10,
    },
    // when window width is >= 1440px
    1440: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      grid: {
        rows: 6,
      },
      spaceBetween: 10,
      navigation: false,
    },
  },

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    bulletClass: 'pb',
    bulletActiveClass: 'active',
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
    clickable: true,
  },
});

//filter
if (document.getElementById('subcategory-menu')) {
    window.addEventListener('beforeunload', function() {
      var buttonContainer = document.getElementById('button-container');
      buttonContainer.scrollLeft = 0;
    });
    var swiperWrapper = document.querySelector('.swiper6 .swiper-wrapper');
    var filterButtons = document.querySelectorAll('.filter-button');

    var container = document.getElementById('button-container');
    var prevButton = document.getElementById('prev-button');
    var nextButton = document.getElementById('next-button');

    // Provera da li se kod izvršava na računaru (desktop uređajima)
    var isDesktop = /Mobi|Tablet|iPad|iPhone|Android/.test(navigator.userAgent) === false;

    if (isDesktop) {
      prevButton.addEventListener('click', function() {
        var buttonWidths = Array.from(container.getElementsByClassName('filter-button')).map(function(button) {
          return button.offsetWidth + parseInt(window.getComputedStyle(button).marginLeft);
        });
        var containerWidth = container.offsetWidth;
        var totalButtonWidth = buttonWidths.reduce(function(sum, width) {
          return sum + width;
        }, 0);
        var scrollStep = Math.min(container.scrollLeft, containerWidth);
        container.scrollBy({
          left: -scrollStep,
          behavior: 'smooth'
        });

        // Provera pozicije kontejnera i upravljanje strelicama samo na računarima
        if (container.scrollLeft <= 0) {
          prevButton.style.visibility = 'hidden'; // Sakrij levu strelicu
        } else {
          prevButton.style.visibility = 'visible'; // Prikazi levu strelicu
        }

        // Uvek omogući desnu strelicu prilikom klika na levu strelicu
        nextButton.style.visibility = 'visible';
      });

      nextButton.addEventListener('click', function() {
        var buttonWidths = Array.from(container.getElementsByClassName('filter-button')).map(function(button) {
          return button.offsetWidth + parseInt(window.getComputedStyle(button).marginLeft);
        });
        var containerWidth = container.offsetWidth;
        var totalButtonWidth = buttonWidths.reduce(function(sum, width) {
          return sum + width;
        }, 0);
        var scrollStep = Math.min(container.scrollWidth - container.scrollLeft - containerWidth, containerWidth);
        container.scrollBy({
          left: scrollStep,
          behavior: 'smooth'
        });

        // Provera pozicije kontejnera i upravljanje strelicama samo na računarima
        if (container.scrollLeft + containerWidth >= container.scrollWidth) {
          nextButton.style.visibility = 'hidden'; // Sakrij desnu strelicu
        } else {
          nextButton.style.visibility = 'visible'; // Prikazi desnu strelicu
        }

        // Uvek omogući levu strelicu prilikom klika na desnu strelicu
        prevButton.style.visibility = 'visible';
      });

      // Provera početne pozicije kontejnera i postavljanje statusa strelica samo na računarima
      if (container.scrollLeft <= 0) {
        prevButton.style.visibility = 'hidden'; // Sakrij levu strelicu
      }

      if (container.scrollLeft + container.offsetWidth >= container.scrollWidth) {
        nextButton.style.visibility = 'hidden'; // Sakrij desnu strelicu
      }
    }




    filterButtons.forEach(function (button) {
      button.addEventListener('click', function () {
        var category = button.getAttribute('data-category');
        

        // Add active class to the clicked button
        filterButtons.forEach(function (btn) {
          btn.classList.remove('active');
        });
        button.classList.add('active');

        
        
          // Filter slides based on category and subcategory
          // Ako je grid opcija 
          // swiperWrapper.querySelectorAll('.swiper-category-row')
          // var subrows = categoryRow.querySelectorAll('.swiper-subrow');
          // 
          swiperWrapper.querySelectorAll('.swiper-subrow').forEach(function (categoryRow) {
            var subrows = categoryRow.querySelectorAll('.swiper-slide');
        
            
        
            if (category === 'all') {
              // Show all subrows of all categories
              subrows.forEach(function (subrow) {
                
                subrow.style.display = 'flex'; // or any other appropriate display property
              });
            } else {
              // Show subrows of the selected category, hide others
              subrows.forEach(function (subrow) {
                    
                if (subrow.getAttribute('data-category') === category) {
                  
                  subrow.style.display = 'flex'; // ili drugi prikladan prikaz
                } else {
                  subrow.style.display = 'none';
                  
                }
              });
            }
          });
        


        // Reinitialize Swiper
        swiper6.destroy();
        swiper6 = new Swiper('.swiper6', {
          // Swiper configuration options
          modules: [Grid, Navigation, Pagination],
          touch: true,
          slidesPerView: 1,
          slidesPerGroup: 1,
          grid: {
            rows: 6,
          },
          spaceBetween: 10,

          loop: false,
          breakpoints: {
            480: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              grid: {
                rows: 3,
              },
            },
            720: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              grid: {
                rows: 3,
              },
            },
            960: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              grid: {
                rows: 3,
              },
              spaceBetween: 10,
            },
            // when window width is >= 1440px
            1440: {
              slidesPerView: 6,
              slidesPerGroup: 6,
              grid: {
                rows: 6,
              },
              spaceBetween: 10,
              navigation: false,
            },
          },

          // If we need pagination
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            bulletClass: 'pb',
            bulletActiveClass: 'active',
            renderBullet: function (index, className) {
              return '<span class="' + className + '">' + (index + 1) + '</span>';
            },
            clickable: true,
          },
        });
      });
    });
  }
var imageObserver = new IntersectionObserver(handleImageIntersection, {
  root: null,
  rootMargin: '10px',
  threshold: 0.1,
});

var images = document.querySelectorAll('img[data-src]');
images.forEach(function (image) {
  var previewImage = new Image();
  previewImage.src = './img/momdolio_logo_jelkice.webp'; // Postavite putanju do predpregledne slike

  image.style.opacity = 0;
  image.classList.add('fade-in');

  image.onload = function () {
    image.style.opacity = 1;
    image.style.transition = 'opacity 1s ease-in-out';
    imageObserver.unobserve(image);
  };

  if (!navigator.onLine || isImageCached(image.dataset.src)) {
    image.src = image.dataset.src;
    image.style.opacity = 1;
  } else {
    image.src = previewImage.src;
  }

  imageObserver.observe(image);
});

function handleImageIntersection(entries, observer) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting) {
      var image = entry.target;
      var previewImage = new Image();
      previewImage.src = './img/momdolio_logo_jelkice.webp'; // Postavite putanju do predpregledne slike

      var imageIntersectionObserver = new IntersectionObserver(function (imageEntries, imageObserver) {
        imageEntries.forEach(function (imageEntry) {
          if (imageEntry.isIntersecting && (!navigator.onLine || !isImageCached(image.dataset.src))) {
            image.src = image.dataset.src;
            image.style.opacity = 1;
            image.style.transition = 'opacity 1s ease-in-out';
            imageObserver.unobserve(image);
          }
        });
      }, { root: null, rootMargin: '10px', threshold: 0.1 });

      imageIntersectionObserver.observe(image);
    }
  });
}

function isImageCached(src) {
  var image = new Image();
  image.src = src;
  return image.complete;
}

// Dodajte osmatrač za proveru zamene slike prilikom skrolovanja i pomeranja stranice
function checkImageIntersection() {
  images.forEach(function (image) {
    if (!navigator.onLine || !isImageCached(image.dataset.src)) {
      var imageIntersectionObserver = new IntersectionObserver(function (imageEntries, imageObserver) {
        imageEntries.forEach(function (imageEntry) {
          if (imageEntry.isIntersecting) {
            image.src = image.dataset.src;
            image.style.opacity = 1;
            image.style.transition = 'opacity 1s ease-in-out';
            imageObserver.unobserve(image);
          }
        });
      }, { root: null, rootMargin: '10px', threshold: 0.1 });

      imageIntersectionObserver.observe(image);
    }
  });
}

// Pozovite funkciju za proveru zamene slike prilikom učitavanja stranice
checkImageIntersection();

// Pozovite funkciju za proveru zamene slike prilikom skrolovanja i pomeranja stranice
window.addEventListener('scroll', checkImageIntersection);
window.addEventListener('resize', checkImageIntersection);


const slides = Array.from(document.querySelectorAll('.swiper-subrow .swiper-slide'));

slides.forEach((slide, index) => {
  const isFirstInCategory = !slides.some((s, i) => i < index && s.getAttribute('data-category') === slide.getAttribute('data-category'));

  if (isFirstInCategory) {
    slide.style.marginTop = '0';
    slide.style.flexDirection = 'column';
  } else {
    slide.style.marginTop = '58px';
  }
});
